<template>
  <div :style="{ height: '100vh', overflow: 'auto' }" class="login1">
    <el-row style="height: 100%">
      <el-col :span="17" type="flex" align="middle">
        <div style="height: 40px; text-align: left">
          <img :src="logoImg" />
        </div>

        <div>
          <div>
            <el-form
              v-if="!isLogin"
              style="width: 340px; margin-top: 200px"
              ref="logForm"
              :rules="rules"
              :model="model"
            >
              <el-form-item>
                <el-row style="width: 100%">
                  <el-col :span="12" style="text-align: left; cursor: pointer">
                    <h1
                      @click="isLogin = !isLogin"
                      :style="{ color: isLogin ? '#86909c' : '' }"
                    >
                      密码登录
                    </h1></el-col
                  >
                  <el-col
                    :span="12"
                    style="text-align: right; cursor: pointer"
                    :style="{ color: isLogin ? '' : '#86909c' }"
                  >
                    <h1 @click="isLogin = !isLogin">注册</h1></el-col
                  >
                </el-row>
              </el-form-item>
              <el-form-item prop="userName">
                <input
                  id="userName"
                  placeholder="手机号或用户名"
                  :class="myInput"
                  v-model="model.userName"
                />
              </el-form-item>
              <el-form-item prop="password">
                <input
                  placeholder="密码"
                  :class="myInput"
                  v-model="model.password"
                  type="password"
                />
              </el-form-item>

              <el-form-item>
                <el-row style="width: 100%">
                  <el-col :span="12" style="text-align: left">
                    <el-checkbox size="mini" v-model="remember">
                      记住密码
                    </el-checkbox>
                  </el-col>
                  <el-col :span="12" style="text-align: right; color: #86909c">
                    <span> 忘记密码 </span>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item>
                <el-button
                  @click="onSubmit('logForm')"
                  style="width: 100%; background: rgb(22, 93, 255)"
                  type="primary"
                  size="large"
                  >立即登录</el-button
                >
              </el-form-item>
            </el-form>
            <el-form
              v-else
              ref="logForm1"
              :model="model"
              :rules="rules1"
              style="width: 450px; margin-top: 100px"
            >
              <el-form-item label-width="50px">
                <el-row style="width: 100%">
                  <el-col :span="12" style="text-align: left; cursor: pointer">
                    <h1
                      @click="isLogin = !isLogin"
                      :style="{ color: isLogin ? '#86909c' : '' }"
                    >
                      密码登录
                    </h1></el-col
                  >
                  <el-col
                    :span="12"
                    style="text-align: right; cursor: pointer"
                    :style="{ color: isLogin ? '' : '#86909c' }"
                  >
                    <h1 @click="isLogin = !isLogin">注册</h1></el-col
                  >
                </el-row>
              </el-form-item>
              <el-form-item label-width="50px" label="姓名" prop="user_name">
                <el-input
                  prefix-icon="user"
                  v-model="model.user_name"
                  placeholder="请填写姓名"
                  size="large"
                />
              </el-form-item>

              <el-form-item
                label="手机"
                required
                label-width="50px"
                prop="mobile"
              >
                <el-input
                  prefix-icon="phone"
                  v-model="model.mobile"
                  placeholder="请填写手机号"
                  size="large"
                />
              </el-form-item>
              <el-form-item
                label="账户"
                required
                label-width="50px"
                prop="account_name"
              >
                <el-input
                  prefix-icon="check"
                  v-model="model.account_name"
                  placeholder="请填写账户名"
                  size="large"
                />
              </el-form-item>

              <el-form-item label="邮箱" label-width="50px">
                <el-input
                  id="userName"
                  prefix-icon="message"
                  v-model="model.email"
                  placeholder="请填写邮箱"
                  size="large"
                />
              </el-form-item>

              <el-form-item label="公司" label-width="50px">
                <el-input
                  id="userName"
                  prefix-icon="MessageBox"
                  v-model="model.company_name"
                  placeholder="请填写公司"
                  size="large"
                />
              </el-form-item>

              <el-form-item label="部门" label-width="50px">
                <el-input
                  prefix-icon="Connection"
                  v-model="model.department"
                  placeholder="请填写部门"
                  size="large"
                />
              </el-form-item>

              <el-form-item
                label="行业"
                class="industry-check-box"
                label-width="50px"
              >
                <el-checkbox-group v-model="model.industry" size="large">
                  <el-row
                    style="
                      width: 100%;
                      border: 1px solid #dcdfe6;
                      border-radius: 4px;
                      padding: 5px;
                    "
                  >
                    <el-col :span="6" style="text-align: left">
                      <el-checkbox label="TMT" value="TMT"></el-checkbox
                    ></el-col>
                    <el-col :span="6" style="text-align: left">
                      <el-checkbox
                        label="展览主办"
                        value="展览主办"
                      ></el-checkbox
                    ></el-col>
                    <el-col :span="6" style="text-align: left">
                      <el-checkbox
                        label="金融保险"
                        value="金融保险"
                      ></el-checkbox
                    ></el-col>
                    <el-col :span="6" style="text-align: left">
                      <el-checkbox
                        label="教育培训"
                        value="教育培训"
                      ></el-checkbox
                    ></el-col>
                    <el-col :span="6" style="text-align: left">
                      <el-checkbox
                        label="医疗医药"
                        value="医疗医药"
                      ></el-checkbox
                    ></el-col>
                    <el-col :span="6" style="text-align: left">
                      <el-checkbox label="其他" value="其他"></el-checkbox
                    ></el-col>
                  </el-row>
                </el-checkbox-group>
              </el-form-item>

              <el-form-item label="备注" label-width="50px">
                <el-input
                  type="textarea"
                  prefix-icon="ChatDotRound"
                  rows="5"
                  v-model="model.notice"
                  placeholder="请输入会议规模、人数和会议时间"
                  size="large"
                />
              </el-form-item>

              <el-form-item label-width="50px">
                <el-button
                  @click="createAccountSelf()"
                  style="width: 100%; background: rgb(22, 93, 255)"
                  type="primary"
                  size="large"
                  >注册</el-button
                >
              </el-form-item>
            </el-form>
          </div>

          <div
            style="
              bottom: 0;
              position: absolute;
              left: 50%;
              transform: translate(-120%, -50%);
            "
          >
            <a
              style="color: #86909c; font-size: 12px; text-decoration: none"
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              >沪ICP备202340378号-1</a
            >
            <span style="color: #86909c; font-size: 12px">{{
              companyCopy
            }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="7">
        <div
          :style="{
            backgroundImage: 'url(' + loginbgImg + ')',
            height: '100%',
            backgroundSize: '100% 100%',
          }"
        ></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginbgImg: "https://koalahuiyi.com/resources/imgs/common/loginbg.png",
      height: "",
      myInput: "my-input",
      companyCopy: "Copyright © 2013-2023  版权所有 ",
      type: true,
      model: {
        username: "",
        password: "",
      },
      eyeShow: true,
      subText: "登录",
      pwdType: "password",
      rules: {
        userName: [{ required: true, message: "请输入手机号或用户名" }],
        password: [{ required: true, message: "请输入密码" }],
      },
      rules1: {
        user_name: [{ required: true, message: "请输入姓名" }],
        mobile: [{ required: true, message: "请输入手机号" }],

        account_name: [{ required: true, message: "请输入账户名" }],
      },
      loginInfor: {
        // logoPath: "上海考拉信息科技有限公司 @copyright 2023 ",
        // loginBtnPosition: 1,
        // backgroundImgPath: require("./assets/bgc.jpg"),
        // loginColor: "orange",
        // color: "#FFF",
        // loginName: "考拉会议",
      },
      logoImg: "https://koalahuiyi.com/resources/imgs/common/logo.png",

      subLoad: false,
      forceLogin: "",
      // tryShow:false
      isLogin: true,
      disableCreate: false,
      remember: true,
      isLogin: false,
    };
  },
  setup() {},
  created() {
    this.getInit();
    // nextTick(() => {
    //   document.getElementById("userName")?.focus();
    // });
    //直接登录
    const accessToken = window.sessionStorage.getItem("accessToken");
    if (
      accessToken != undefined &&
      accessToken != "" &&
      accessToken != null &&
      accessToken != "undefined"
    ) {
      this.doLogin();
    }
  },

  methods: {
    getInit() {
      this.height = window.innerHeight + "px";
    },

    setStyle() {
      this.myInput = "my-input2";
    },
    passShow() {
      this.eyeShow = !this.eyeShow;
      this.pwdType = this.pwdType === "password" ? "text" : "password";
    },

    createAccountSelf() {
      this.$refs.logForm1.validate(async (valid) => {
        if (!valid) {
          return false;
        } else {
          this.doCreateAccountSelf();
        }
      });
    },
    /**
     * 用户注册
     */
    async doCreateAccountSelf() {
      // if (!this.model.account_name) {
      //   this.errorMsgBox("账户名称不能为空！");
      //   return false;
      // }
      // if (!this.model.mobile) {
      //   this.errorMsgBox("密码不能为空！");
      //   return false;
      // }
      let data = {};
      data.params = JSON.stringify(this.model);
      this.disableCreate = true;
      const res = await this.withLoading(
        this.apiAll(data, "/createAccountSelf")
      );
      this.disableCreate = false;
      if (res.code == 0) {
        this.successMsgBox("注册成功！");
        this.isLogin = true;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async doLogin() {
      const ar = _.split(window.location.href, "#");
      const result = "";
      let params = {};
      let data = {};

      params.ipSuffix = ar[0];
      params.systemUrl = ar[0];
      params.userName = this.model.userName;
      params.password = this.model.password;
      params.forceLogin = this.forceLogin;
      data.params = JSON.stringify(params);
      this.subLoad = true;
      this.subText = "登陆中";

      const loginMes = await this.withLoading(
        this.apiAll(data, "/loginAccount")
      );
      this.subLoad = false;
      this.subText = "登陆";
      if (loginMes.code == 0) {
        localStorage.setItem(
          "kaola_accountInfo",
          JSON.stringify(loginMes.extra.accountInfo)
        );
        localStorage.setItem("kaola_accessToken", loginMes.extra.accessToken);

        //默认跳到活动页面
        let tab = {};
        tab.title = "会议管理";
        tab.name = "events";
        let editableTabs = [];
        editableTabs.push(tab);
        window.sessionStorage.setItem("tabs", JSON.stringify(editableTabs));
        window.sessionStorage.setItem("currentTab", "events");

        this.$router.push("events");
      } else {
        this.errorMsgBox(loginMes.message);
      }
    },

    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) {
          return false;
        } else {
          this.doLogin();
        }
      });
    },
    reg() {
      this.$router.push("/reg");
    },
  },
};
</script>
<style scope lang="less">
.login1 {
  .my-input {
    background: #e5e6eb;
    width: 100%;
    border: none;
    height: 40px;
    text-indent: 4px;
  }
  .my-input:focus {
    outline: none;

    background: transparent;
    border: 1px solid rgb(22, 93, 255);
  }
  .my-input2 {
    background: #fdcdc5;
    width: 100%;
    border: none;
    height: 40px;
    text-indent: 4px; //首行缩进
  }
  .el-checkbox__input {
    border: none;
    .el-checkbox.el-checkbox--large .el-checkbox__inner {
      height: auto;
      width: auto;
    }
  }
}
</style>
