<template>
  <div
    style="padding: 10px"
    class="system-index"
    :style="{ maxHeight: maxHeight, overFlow: 'auto' }"
  >
    <el-row>
      <el-col style="height: 70px">
        <el-card>
          <!-- <span
            style="
              width: 60px;
              height: 60px;
              margin-right: 20px;
              border-radius: 50%;
            "
          >
            <img :src="userInfo.imgUrl" style="width: 100%; height: 100%;" />
          </span> -->
          <span>
            晚上好
            <span style="color: black; font-size: 20px">
              {{ userInfo.userName }}
            </span>
            <span>今天是{{ currentDtme }}号</span>
            {{ indexContent }}
          </span>
        </el-card>
      </el-col>
      <el-col style="margin-top: 10px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-card style="height: 400px">
              <template #header>
                <div class="clearfix">
                  <span>今日事项</span>
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                    @click="openDiary"
                  >
                    新建事项
                  </el-button>
                </div></template
              >
              <el-row v-for="d in diaryList" :key="d.id">
                <el-col :span="22">
                  <span>{{ d.diary_content }}</span>
                </el-col>
                <el-col :span="2">
                  <el-button
                    type="text"
                    :style="{ color: d.is_complete == 1 ? '' : 'red' }"
                  >
                    {{ d.is_complete == 1 ? "完成" : "未完成" }}
                  </el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>

          <el-col :span="12">
            <el-card style="height: 400px">
              <el-calendar>
                <template #date-cell="{ data }">
                  <p
                    :class="data.isSelected ? 'is-selected' : ''"
                    @click="getDate(data)"
                  >
                    {{ data.day.split("-").slice(1).join("-") }}
                    {{ data.isSelected ? "✔️" : "" }}
                  </p>

                  <span v-if="data.day == currentDtme">待办事项</span>
                </template>
              </el-calendar>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col style="margin-top: 10px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-card style="height: 400px">
              <template #header>
                <div class="clearfix">
                  <span>数据统计</span>
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                  ></el-button>
                </div>
                <ve-pie :data="chartData"></ve-pie
              ></template>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <template #header>
                <div class="clearfix">
                  <span>销售统计</span>
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                  ></el-button>
                </div>
                <ve-histogram :data="chartData"></ve-histogram
              ></template>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <template #header>
                <div class="clearfix">
                  <span>销售统计</span>
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                  ></el-button>
                </div>
                <ve-funnel :data="chartData"></ve-funnel
              ></template>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <div>
      <new-model-form-dialog
        title="新建事项"
        :visiblesync="diaryShow"
        width="30%"
        submitText="确认"
        :model="model"
        :fields="fields"
        @submit="saveDiary"
        @reset="diaryShow = false"
      ></new-model-form-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      getDiaryM: "simple.cloud.diary.get",
      addDiary: "simple.cloud.diary.add",
      diaryList: [],
      chartData: {
        columns: ["日期", "销售额", "利息"],
        rows: [
          { 日期: "1月1日", 销售额: 1, 利息: 1 },
          { 日期: "1月2日", 销售额: 2, 利息: 1 },
          { 日期: "1月3日", 销售额: 3, 利息: 1 },
          { 日期: "1月4日", 销售额: 4, 利息: 1 },
          { 日期: "1月5日", 销售额: 4, 利息: 2 },
          { 日期: "1月6日", 销售额: 5, 利息: 4 },
        ],
      },

      diaryShow: false,
      model: {},
      fields: [
        {
          name: "date",
          title: "日期",
          type: "disabled",
        },
        {
          name: "content",
          title: "内容",
          type: "text",
        },
      ],
      indexContent: "",
      maxHeight: "",
    };
  },
  created() {
    this.getInit();
    this.open1();
  },

  methods: {
    getInit() {
      this.maxHeight = window.innerHeight - 100 + "px";
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      if (sessionStorage.getItem("infoMap")) {
        this.indexContent = JSON.parse(
          sessionStorage.getItem("infoMap")
        ).index_content;
      }
      this.getCurrentTime();
      this.getDiary();
    },
    getDate(data) {
      this.getDiary(data.day);
    },

    async getDiary(date) {
      let resp = await this.withLoading(
        this.apiAll(this.$common.simpleGet(this.getDiaryM, date))
      );
      if (resp.code < 0) {
        this.errorMsgBox(resp.message);
      } else {
        this.diaryList = resp.extra;
      }
    },
    async saveDiary() {
      let resp = await this.withLoading(
        this.apiAll(
          this.$common.simpleDo(
            this.addDiary,
            this.model.content,
            this.model.date
          )
        )
      );
      if (resp.code < 0) {
        this.errorMsgBox(resp.message);
      } else {
        this.successMsgBox(resp.message);
        this.diaryShow = false;
        this.getDiary();
      }
    },
    openDiary() {
      this.diaryShow = true;
      this.$set(this.model, "date", this.currentDtme);
    },
    // 获取当前日期
    getCurrentTime() {
      let currentTime = new Date(),
        year = currentTime.getFullYear(),
        month =
          currentTime.getMonth() + 1 < 10
            ? "0" + (currentTime.getMonth() + 1)
            : currentTime.getMonth() + 1,
        day =
          currentTime.getDate() < 10
            ? "0" + currentTime.getDate()
            : currentTime.getDate();
      this.currentDtme = year + "-" + month + "-" + day;
    },

    comparedate(date1) {
      let oDate1 = new Date(date1);
      let oDate2 = new Date(this.currentDtme);
      if (oDate1.getTime() == oDate2.getTime()) {
        return true;
      } else if (oDate1.getTime() > oDate2.getTime()) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="less"></style>
